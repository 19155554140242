@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.slider {
  background: var(--white);
  overflow: hidden;
  //   padding-top: 1rem;
  //   padding-bottom: 1rem;
  white-space: nowrap;
}

.slider:hover .slide-track {
  animation-play-state: paused;
}

.slide-track {
  display: flex;
  animation: 15s slide infinite linear;
  /* display: inline-block; */
}

.slide-track img {
  margin: 0 40px;
}
