.contact-section {
  min-height: 100vh;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-title {
  letter-spacing: 3px;
}
.contact-company-subtext {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .contact-company-subtext {
    width: 800px;
  }
}

.footer-input {
  background: var(--white);
  color: #00000099;
  border: none;
  padding: 15px 10px;
  border-radius: 5px;
  flex: 1;
  height: 48px;
  box-shadow: var(--box-shadow);

  &::placeholder {
    color: #00000099;
    font-size: 0.9rem;
  }

  &:focus {
    outline: none;
  }
}

.textarea-footer-input {
  width: 100%;
  border: none;
  background: var(--white);
  color: #00000099;
  border: none;
  padding: 10px 0px 0px 10px;
  min-height: 45px;
  border-radius: 5px;
  box-shadow: var(--box-shadow);

  &::placeholder {
    color: #00000099;
    font-size: 0.9rem;
  }

  &:focus {
    outline: none;
  }
}

.contact-image {
  width: 330px;
  //   width: 100%;
}
.contact-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
