.about-section {
  min-height: 60vh;
  padding-top: 100px;
  // background: var(--white);
  background: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-image {
  width: 600px;
}
.about-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.about-company-subtext {
  width: 100%;
}
.about-title {
  letter-spacing: 3px;
}

@media screen and (min-width: 1024px) {
  .about-company-subtext {
    width: 800px;
  }
}
