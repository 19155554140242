.footer-section {
  background-color: var(--primary);
}

.copy_rights {
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: 400;
  color: var(--white);
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer_description p {
  padding-top: 1rem;
  color: var(--white);
  font-size: 14px;
  width: 300px;
}

.footer_side {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer_title {
  font-size: 18px;
  font-weight: bold;
  color: var(--white);
  letter-spacing: 1px;
}

.sub_heading {
  font-size: 14px;
  padding: 0.5rem 0rem;
  color: var(--white);
}

.border_left {
  margin-left: 20px;
  border-left: 2px solid #bcbcbc;
  padding-left: 1rem;
}

.media {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
}

.media_icons {
  color: var(--white);
}
.company-logo {
  width: 50px;
}
.company-logo img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.company-logo-text {
  font-size: 22px;
  letter-spacing: 2px;
}

@media screen and (max-width: 768px) {
  .company_logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .footer_description p {
    text-align: center;
  }

  .footer_things {
    padding-top: 3rem;
  }

  .sub_heading {
    text-align: center;
  }

  .footer_title {
    text-align: center;
  }

  .footer_side {
    display: grid;
    justify-content: center;
  }
}
